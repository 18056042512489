import React from 'react';
import { Switch,BrowserRouter } from 'react-router-dom';
import AppRoute from './layouts/App_route';

import UserRoute from './layouts/UserRoute';
// import RetailerRoute from './layouts/RetailerRoute';
import Default from './layouts/DefaultLayout';
import Full from './layouts/FullLayout';
import UserLayout from './layouts/UserLayout';
// import RetailerLayout from './layouts/RetailerLayout';

//web page
import Home from "./pages/web/Home";
import Pricing from "./pages/web/Pricing";
// import Contact from "./pages/web/Contact";
// import AboutUs from "./pages/web/AboutUs";

// auth
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
// import Register from "./pages/Auth/Register";

//cart
import Cart from "./pages/web/Cart";
import CheckOut from "./pages/web/CheckOut";
import PaymentSuccess from "./pages/web/PaymentSuccess";
import PaymentFailed from "./pages/web/PaymentFailed";

//Bothe
// import UserActivityLog from "./pages/Bothe/UserActivityLog";
import ChangePassword from "./pages/Bothe/ChangePassword";
// import ViewProfile from "./pages/Bothe/ViewProfile";
// import MyRechargeList from "./pages/Bothe/RechargeList";

// User
import UserDashboard from "./pages/User/Dashboard";
import Subscription from "./pages/User/Subscription";
import Card from "./pages/User/Card";
import Profile from "./pages/User/Profile/Profile";
import EditProfile from "./pages/User/Profile/EditProfile";
import AddCard from "./pages/User/AddCard";
import Order from "./pages/User/Order";
import UserCart from "./pages/User/Cart";
import Payment from "./pages/User/Payment";
import Leads from "./pages/User/Leads";


import ViewProfile from "./theme/Theme";




const App = () => {
   
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/view-profile/:id" component={ViewProfile} layout={Full} />
                <AppRoute exact path="/login" component={Login} layout={Default} />
                <AppRoute exact path="/logout" component={Logout} layout={Default} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Default} />
                {/* <AppRoute exact path="/sign-up" component={Register} layout={Full} /> */}

                <AppRoute exact path="/" component={Home} layout={Default} />
                <AppRoute exact path="/pricing" component={Pricing} layout={Default} />
                <AppRoute exact path="/cart" component={Cart} layout={Default} />
                <AppRoute exact path="/check-out" component={CheckOut} layout={Default} />
                <AppRoute exact path="/successful" component={PaymentSuccess} layout={Default} />
                <AppRoute exact path="/failed" component={PaymentFailed} layout={Default} />

                {/* User */}
                <UserRoute exact path="/dashboard" component={UserDashboard} layout={UserLayout} />
                <UserRoute exact path="/subscription" component={Subscription} layout={UserLayout} />
                <UserRoute exact path="/leads" component={Leads} layout={UserLayout} />
                <UserRoute exact path="/cardes" component={Card} layout={UserLayout} />
                <UserRoute exact path="/cardes/add" component={AddCard} layout={UserLayout} />
                <UserRoute exact path="/order" component={Order} layout={UserLayout} />
                <UserRoute exact path="/profile" component={Profile} layout={UserLayout} />
                <UserRoute exact path="/profile/edit/:id?" component={EditProfile} layout={UserLayout} />
                <UserRoute exact path="/user-cart" component={UserCart} layout={UserLayout} />
                <UserRoute exact path="/payment" component={Payment} layout={UserLayout} />
                <UserRoute exact path="/change-password" component={ChangePassword} layout={UserLayout} />


            </Switch>
        </BrowserRouter>
        </> 
    );
}

export default App;
