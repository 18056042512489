import React from 'react';
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types"

import tapgrid_gif_transparent from './../../assets/images/tapgrid_gif_transparent.gif';
import Section_02 from './../../assets/images/Section_02.png';
import Section_04 from './../../assets/images/Section_04.png';
import Section_06 from './../../assets/images/Section_06.png';

const Home = props => {
    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <img src={tapgrid_gif_transparent} className="img-fluid" />
                        </div>
                        <div className="col-sm-6">
                            <h1 className="font-family-regular mb-0 ">Your One Tap</h1>
                            <h1 className="font-family-bold ">Smart Business Card</h1>
                            <p className="font-family-regular pt-2 mb-3 pb-2">
                                You are just one tap away from sharing your contact information.
                                Tap your card and it is done! Make changes anywhere, anytime and
                                instantly share with any smartphone or tablet.
                            </p>
                            <Link to="/pricing" className="btn cbtn-primary text-uppercase">Shop Now</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section bg-gray position-relative">
                <div className="container">
                    <div className="align-items-center row">
                        <div className="col-sm-6">
                            <div className="pr-lg-5">
                                <div className="watermark text-uppercase">Card</div>
                                <p className="text-uppercase font-weight-bold mb-0">Expand Your Network</p>
                                <h1 className="mb-4 font-weight-bold">Digitize your Business Card</h1>
                                <hr className="hr" />
                                <p className="pb-2 pt-2">
                                    Tapgrid is super easy to use. Sharing your Contact Information
                                    and Socials couldn't have been easier.
                                </p>
                                <p className="mb-5 font-weight-bold">Just one tap and Voila! it's done!</p>
                                <Link to="/pricing" className="btn cbtn-primary text-uppercase">Get Your Card Now</Link>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <img src={Section_02} className="mx-auto" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section more-section-background position-relative">
                <div className="watermark watermark-app-top text-uppercase">No App</div>
                <div className="watermark watermark-tap-top text-uppercase">Only Tap</div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="title text-center">
                                <h1 className="font-weight-bold text-dark">
                                    <span className="text-white">Professionalism at it's peak! You can't network better</span>
                                </h1>
                                <p className="subcontent-margin">
                                    The One tap Smart Business Cards by tapgrid are the best to
                                    connect not just for face-to face meetings but also for Virtual
                                    networking and Online Events.
                                </p>
                                <p className="subcontent-margin">
                                    Smartphone compatibility - Oh Yeah! Compatible with all iOS and
                                    Android devices.
                                </p>
                                <p className="subcontent-margin">
                                    Tap Grid Virtual Business Cards:
                                </p>
                                <p className="text-uppercase tap-css">No App Only Tap!</p>
                                <Link to="/" className="btn cbtn-white text-uppercase">
                                    Know More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative overflow-hidden">
                <div className="align-items-center row ">
                    <div className="col-sm-6 p-0">
                        <div className="mt-lg-0">
                            <img src={Section_04} className="" />
                        </div>
                    </div>
                    <div className="col-sm-6 p-0 vertical-center">
                        <div className="watermark watermark-work-top text-uppercase">Work?</div>
                        <p className="text-uppercase font-weight-bold mb-0">All-in-one</p>
                        <h1 className="mb-4 font-weight-bold">How does it Work?</h1>
                        <hr className="hr" />
                        <h5 className="font-weight-bold">Grab your Card</h5>
                        <p className="pb-2">
                            Choose your card from the wide variety of choices we provide. These
                            can be customized as well
                        </p>
                        <h5 className="font-weight-bold">Create your Profile</h5>
                        <p className="pb-2">
                            Choose your card from the wide variety of choices we provide. These
                            can be customized as well
                        </p>
                        <h5 className="font-weight-bold">Tap and Connect</h5>
                        <p className="pb-2">
                            Choose your card from the wide variety of choices we provide. These
                            can be customized as well
                        </p>

                        <Link to="/" className="btn cbtn-primary text-uppercase">
                            Know more
                        </Link>
                    </div>
                </div>
            </section>

            <section className="section bg-gray">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="pr-lg-5">
                                <div className="watermark watermark-share-top text-uppercase">Share</div>
                                <h5 className="font-weight-bold">Your Contact Info</h5>
                                <p className="pb-2">
                                    Create a profile that best represents your brnd and choose what
                                    you want to share. Over 30 networks supported!
                                </p>
                                <h5 className="font-weight-bold">Social Networks</h5>
                                <p className="pb-2">
                                    Select what and where to share and start connecting over a
                                    number of social media Platforms. Facebook, Instagram, Tiktok,
                                    Youtube to name a few!
                                </p>
                                <h5 className="font-weight-bold">Calendar and more...</h5>
                                <p className="pb-2">
                                    From Calendars to Menus, it allows you to custom link fields and
                                    lets you share any URL you wish to.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <img src={Section_06} alt="" className="img-fluid mx-auto d-block" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section more-section-background position-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <div className="title text-center">
                                <div className="text-font-size font-family-regular text-uppercase text-white line-height">
                                    Grow Your <br /> business Network <br /> With Just a Tap
                                </div>
                                <p className="subcontent-margin mt-4 mb-4">
                                    Networking couldn't be easier than this. Just a single tap and
                                    share all <br /> your contact information including your Social Media
                                    profiles. <br /> TapGrid - Your Go to Online Business Card.
                                </p>
                                <Link to="/pricing" className="btn cbtn-white text-uppercase">Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(Home)
Home.propTypes = {
    history: PropTypes.object,
}