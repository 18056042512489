// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"
export const POST_SIGN_UP = "/auth/sign-up"

export const GET_PRICING = "/web/get-price"
export const GET_CART = "/web/get-cart"
export const GET_COUNTRIES = "/web/get-countries"
export const GET_STATES = "/web/get-states"
export const POST_GET_PROFILE = "/web/get-profile"
export const POST_SEND_INQUIRY = "/web/send-inquiry"

export const ORDER_PLEASE = "/web/order-please"
export const UPDATE_ORDER_STATUS = "/web/update-order-status"

export const GET_THEMES = "/profile/get-themes"
export const POST_ADD_PROFILE = "/profile/add-profile"
export const GET_USER_PROFILE_LIST = "/profile/get-user-profile-list"
export const DELETE_USER_PROFILE = "/profile/delete-user-profile"
export const GET_PROFILE_DETAILES = "/profile/detailes"
export const POST_SAVE_PROFILE_DETAILES = "/profile/save_detailes"
export const GET_LINKS = "/profile/get-links"
export const POST_ADD_EDIT_LINK = "/profile/add-edit-link"
export const GET_USER_LINK = "/profile/get-user-links"
export const DELETE_USER_LINK = "/profile/delete-user-link"
export const POST_SAVE_COLOR = "/profile/save-color"
export const POST_SAVE_CONTACT_FORM = "/profile/save-contact-form"
export const POST_SAVE_BANNER_LOGO = "/profile/save-banner-logo"
export const POST_REMOVE_BANNER_LOGO = "/profile/remove-banner-logo"
export const GET_All_LEADS = process.env.REACT_APP_API_URL+"profile/get-all-leads"
export const DELETE_LEAD = process.env.REACT_APP_API_URL+"profile/remove-leads"

export const GET_USER_PAYMENT = process.env.REACT_APP_API_URL+"payment/get-all-payment"
export const GET_PAYMENT_DETAIL = "/payment/get-payment-detail"
export const GET_USER_SUBSCRIPTION = process.env.REACT_APP_API_URL+"payment/get-user-subscription"


//bothe
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"
export const GET_USER_INFO = "/com/get-user-info"

export const GET_USER_BALANCE = "/com/get-user-balance"
export const GET_STATE = "/com/get-country-based-states"
export const GET_CITY = "/com/get-states-based-city"
