import React, { useState, useRef, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { post } from '../../../helpers/api_helper';
import { img_view } from '../../../helpers/Helper';
import { GET_PROFILE_DETAILES, POST_SAVE_COLOR,POST_SAVE_BANNER_LOGO,POST_REMOVE_BANNER_LOGO } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';
import UserImage from './../../../assets/images/user.png';
const Theme = props => {
    let history = useHistory();

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ 'theme_id': props.match.params.id, theme_color: '', photo: '', banner_photo: '' });

    useEffect(() => {
        async function get_detaile() {
           await get_theme_detail();
        }
        get_detaile();
    }, [props.match.params.id])

    const get_theme_detail = async () => {
        var res_data = await post(GET_PROFILE_DETAILES, { 'id': props.match.params.id }, {});
        if (res_data.status) {
            setInputs({ 'theme_id': props.match.params.id, theme_color: res_data.data.detail.theme_color, photo: res_data.data.detail.photo, banner_photo: res_data.data.detail.banner_photo });
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // form submit event
    const set_color = async (event) => {
        SetButtonDisabled(true);
        var res_data = await post(POST_SAVE_COLOR, form_inputs, {});
        if (res_data.status) {
            toast(res_data.message);
        } else {
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    }

    // remove image
    const remove_image = async (type) => {
        var res_data = await post(POST_REMOVE_BANNER_LOGO, {'theme_id': props.match.params.id , 'type': type});
        if (res_data.status) {
            toast(res_data.message);
            get_theme_detail();
        } else {
            toast.error(res_data.message);
        }
    }

    const colorChange = (colors) => {
        setInputs(inputs => ({ ...form_inputs, ['theme_color']: colors.color }));
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'logo' || event.target.name === 'banner') {
            var value = event.target.files[0];
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['jpg', 'jpeg', 'png'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Alludu only file jpg , jpeg and png.');
                event.target.value = null;
                return false;
            }

            if (file_size > 1) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = async function () {
                var res_data = await post(POST_SAVE_BANNER_LOGO, {'theme_id': props.match.params.id,'type':event.target.name,'image':reader.result});
                if (res_data.status) {
                    toast(res_data.message);
                    await get_theme_detail();
                } else {
                    toast.error(res_data.message);
                }
            }
            reader.readAsDataURL(file);
        }

        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    return (
        <>
            <div className='row mt-3'>
                <div className='col-sm-5'>
                    <div className='card'>
                        <div className="card-header">
                            <h5 className='m-0'>Photo/Logo</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group mb-3">
                                <label className="form-label">Use 400x400 pixel photo for better result.</label><br />
                                <div className='text-center mt-3'>
                                    <label className='rounded-logo' for="logo">
                                        {!form_inputs.photo && <img src={UserImage} alt="logo" />}
                                        {form_inputs.photo && <img src={img_view(form_inputs.photo+'?'+new Date().getTime())} alt="logo" />}
                                        <span><i class="fa fa-camera" aria-hidden="true"></i></span>
                                    </label>
                                    <input name="logo" id='logo' onChange={handleInputChange} className="form-control none" type="file" accept="image/*" />
                                </div>
                                <div className='text-center'>
                                {form_inputs.photo && <span className='pointer' onClick={remove_image.bind(this,'logo')}>Remove Photo</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-7'>
                    <div className='card'>
                        <div className="card-header">
                            <h5 className='m-0'>Banner Photo</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group mb-3">
                                <label className="form-label">Use 720x405 pixel photo for better result.</label><br />
                                <div className='text-center mt-3'>
                                    <label className='Banner-logo' for="Banner">
                                        {!form_inputs.banner_photo && <img src={UserImage} alt="logo" />}
                                        {form_inputs.banner_photo && <img src={img_view(form_inputs.banner_photo+'?'+new Date().getTime())} alt="banner" />}
                                        <span><i class="fa fa-camera" aria-hidden="true"></i></span>
                                    </label>
                                    <input name="banner" id='Banner' onChange={handleInputChange} className="form-control none" type="file" accept="image/*" />
                                </div>
                                <div className='text-center'>
                                    {form_inputs.banner_photo && <span className='pointer' onClick={remove_image.bind(this,'banner')}>Remove Photo</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-6'>
                    <div className='card'>
                        <div className="card-header">
                            <h5 className='m-0'>Colors</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group mb-3">
                                <label className="form-label mb-3">Match your card with your brand by choosing colors from eight beautiful color pallets or any color on the rainbow.</label><br />
                                <div style={{ margin: '0px' }}>
                                    <ColorPicker animation="slide-up" color={form_inputs.theme_color} onChange={colorChange} />
                                </div>
                                <button onClick={set_color} disabled={ButtonDisabled} className='btn btn-primary c-btn-primary btn-sm mt-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Set color</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Theme);
