import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
// import { get } from '../../helpers/api_helper';
// import { GET_DISTRIBUTOR_DASHBOARD } from '../../helpers/url_helper';
// import { toast } from 'react-toastify';

const Card = props => {
    // const [totalBalance, SetTotalBalance] = useState(0);
    // const [totalRetailer, SetTotalRetailer] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            // await get_service();
        }
        get_detaile();
    }, [])

    // const get_service = async () => {
    //     var res_data = await get(GET_DISTRIBUTOR_DASHBOARD, {});
    //     if (res_data.status) {
    //         SetTotalBalance(res_data.data.total_balance)
    //         SetTotalRetailer(res_data.data.total_retailer)
    //     } else {
    //         toast.error(res_data.message);
    //     }
    // }

    return (
        <>
            <div className='row'>
                <div className='col-sm-6'>
                    <h5>Your cards</h5>
                </div>
                <div className='col-sm-6 text-right'>
                    <h5>Order New Card Avl : 1/10 <Link to="/cardes/add" className="btn c-btn-primary text-white btn-sm ml-3 pt-1 pb-1">+ Add</Link></h5>
                </div>
            </div>
            <div className='row mt-15'>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <div className="dropdown card-option">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <Link className="dropdown-item" type="button">View</Link>
                                    <Link className="dropdown-item" type="button">Change Profile</Link>
                                    <Link className="dropdown-item" type="button">Change Theme</Link>
                                </div>
                            </div>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>
                                <Link to="/user-cart" ><span className='cart-btn' ><i className="fa fa-shopping-cart" aria-hidden="true"></i></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <div className="dropdown card-option">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <Link className="dropdown-item" type="button">View</Link>
                                    <Link className="dropdown-item" type="button">Change Profile</Link>
                                    <Link className="dropdown-item" type="button">Change Theme</Link>
                                </div>
                            </div>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>
                                <Link to="/user-cart" ><span className='cart-btn' ><i className="fa fa-shopping-cart" aria-hidden="true"></i></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <div className="dropdown card-option">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <Link className="dropdown-item" type="button">View</Link>
                                    <Link className="dropdown-item" type="button">Change Profile</Link>
                                    <Link className="dropdown-item" type="button">Change Theme</Link>
                                </div>
                            </div>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <div className="dropdown card-option">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <Link className="dropdown-item" type="button">View</Link>
                                    <Link className="dropdown-item" type="button">Change Profile</Link>
                                    <Link className="dropdown-item" type="button">Change Theme</Link>
                                </div>
                            </div>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <div className="dropdown card-option">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <Link className="dropdown-item" type="button">View</Link>
                                    <Link className="dropdown-item" type="button">Change Profile</Link>
                                    <Link className="dropdown-item" type="button">Change Theme</Link>
                                </div>
                            </div>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
                
                

            </div>
        </>
    );
}

export default withRouter(Card);
