import React, { useState, useRef } from 'react';
import PropTypes from "prop-types"
import { useHistory, withRouter, Link } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_LOGIN } from '../../helpers/url_helper';
import { setUserSession, getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

const Login = props => {
    let history = useHistory();
    if (getToken()) {
        history.push("/");
    }

    const simpleValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [login_inputs, setInputs] = useState({ email: '', password: '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var md5 = require('md5');
            login_inputs.password = md5(login_inputs.password);
            var res_data = await post(POST_LOGIN, login_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                history.push("/dashboard");
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...login_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <section className="auth_section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="bg-white col-lg-10">
                            <div className="col-sm-12 align-items-center">
                                <div className="row">
                                    <div className="col-sm-6 login_img">
                                        <div className="p-5 m-4">
                                            <h1 className="text-white mt-5">Welcome to TapGrid!</h1>
                                            <h5 className="mt-4">Tapgrid is created for smart business card</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="p-4">
                                            <h3 className="mb-4 mt-3 font-family-bold">Sign In</h3>
                                            <form className="signin-form" onSubmit={handleSubmit}>
                                                <div className="form-group mb-3">
                                                    <label className="label">Email</label>
                                                    <input type="text" name='email' className="form-control" value={login_inputs.email} onChange={handleInputChange} placeholder="Email" />
                                                    {simpleValidator.current.message('email', login_inputs.email, 'required|email')}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="label">Password</label>
                                                    <input type="password" name='password' className="form-control" value={login_inputs.password} onChange={handleInputChange} placeholder="Password" />
                                                    {simpleValidator.current.message('password', login_inputs.password, 'required')}
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit" disabled={ButtonDisabled}
                                                        className="form-control btn cbtn-black rounded px-3">{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Sign
                                                        In</button>
                                                </div>
                                                <div className="form-group d-md-flex">
                                                    <div className="w-50 text-left">

                                                    </div>
                                                    <div className="w-50 text-md-right">
                                                        <Link to="/forgot-password">Forgot Password</Link>
                                                    </div>
                                                </div>
                                                {/* <p className="text-center">Not a member? <Link to="/sign-up">Sign Up</Link></p> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(Login)
Login.propTypes = {
    history: PropTypes.object,
}
