import React, { useState, useRef, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { post } from '../../../helpers/api_helper';
import { GET_PROFILE_DETAILES, POST_SAVE_PROFILE_DETAILES } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { DefaultEditor } from 'react-simple-wysiwyg';

const Details = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ theme_id: '', name: '', heading: '', company_name: '', location: '', about: '' });
    const [about, setAbout] = useState('');
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(GET_PROFILE_DETAILES, { 'id': props.match.params.id }, {});
            if (res_data.status) {
                setInputs(res_data.data.detail);
                setAbout(res_data.data.detail.about);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [props.match.params.id])



    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            form_inputs.about = about
            var res_data = await post(POST_SAVE_PROFILE_DETAILES, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }
    const editerChange = (event) => {
        setAbout(event.target.value);
    }


    return (
        <>
            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <h5 className='m-0'>Basic Details</h5>
                        </div>
                        <div className='card-body'>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">Your Full Name</label>
                                    <input name="name" value={form_inputs.name} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Heading</label>
                                    <input name="heading" value={form_inputs.heading} onChange={handleInputChange} className="form-control" type="text" />
                                    <small>Ex. CEO, CTO, Digital Marketer, Advocate, Manager, Electrician, Unisex Salon, Tour & Travel, Photographer, etc</small>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Company Name</label>
                                    <input name="company_name" value={form_inputs.company_name} onChange={handleInputChange} className="form-control" type="text" />
                                    <small>Ex. ACME Private Limited</small>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Location</label>
                                    <input name="location" value={form_inputs.location} onChange={handleInputChange} className="form-control" type="text" />
                                    <small>Ex. New York, USA</small>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">About</label>
                                    <DefaultEditor name="about" value={about} onChange={editerChange} />
                                </div>


                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Details);
