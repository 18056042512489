import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { post } from '../helpers/api_helper';
import { img_view } from '../helpers/Helper';
import { POST_GET_PROFILE, POST_SEND_INQUIRY } from '../helpers/url_helper';
import SimpleReactValidator from 'simple-react-validator';

const Services = props => {
    let history = useHistory();

    const [ActiveTab, SetActiveTab] = useState('home');
    const [UserInfo, SetUserInfo] = useState({});
    const [InquiryForm, SetInquiryForm] = useState({ theme_id: 0, name: '', email: '', mobile_number: '', message: '' });
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [InquirySendSuccess, SetInquirySendSuccess] = useState(false);


    useEffect(() => {
        async function get_detaile() {
            var res_data = await post(POST_GET_PROFILE, { 'id': props.match.params.id });
            if (res_data.status) {
                SetUserInfo(res_data.data.user_info);
                SetInquiryForm({ theme_id: res_data.data.user_info.theme_id });
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [props.match.params.id])

    const link_clikc = (data) => {
        if (data.name == 'Phone') {
            window.open('tel:' + data.value, '_blank', 'noreferrer');
        } else if (data.name == 'Email') {
            window.location.replace('mailto:' + data.value);
        } else if (data.name == 'Skype' || data.name == 'Paytm' || data.name == 'Google Pay' || data.name == 'PhonePe' || data.name == 'UPI') {
            copyToClipboard(data.value);
        } else if (data.name == 'Whatsapp') {
            window.open('https://api.whatsapp.com/send?phone=' + data.value + '&text=hii', '_blank', 'noreferrer');
        } else {
            window.open(data.value, '_blank', 'noreferrer');
        }
    }

    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast('Link copied successfully')
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetInquiryForm(inputs => ({ ...InquiryForm, [event.target.name]: event.target.value }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_SEND_INQUIRY, InquiryForm, {});
            if (res_data.status) {
                SetInquiryForm({ theme_id: UserInfo.theme_id, name: '', email: '', mobile_number: '', message: '' });
                SetInquirySendSuccess(true);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const downloadToFile = (content, filename, contentType) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    }

    const save_to_contact = () => {
        var contact_no = '';
        if(UserInfo.user_links){
            for(var user_links of UserInfo.user_links){
                for(var links of user_links.links){
                    if(links.name == 'Phone'){
                        contact_no += "\nTEL;TYPE=work,voice:"+links.value
                    }else if(links.name == 'Email'){
                        contact_no += "\nEMAIL;TYPE=work,pref:"+links.value
                    }
                }
            }
        }
        // EMAIL;TYPE=work,pref:automaclubricant2021@gmail.com
        // TEL;TYPE=work,voice:+91 8802740936
        let vcard = `BEGIN:VCARD\nVERSION:3.0\nFN:${UserInfo.name}\nORG:${UserInfo.heading} - ${UserInfo.company_name}\nTITLE:Owner${contact_no}\nADR;TYPE=work:;;${UserInfo.location}\nEND:VCARD`;
        downloadToFile(vcard, UserInfo.name+'-vcard.vcf', 'text/vcard');
    }

    return (
        <>
            <section className="viee_profile" style={{ 'backgroundColor': `${UserInfo.theme_color}` }}>
                <div className="profile_box">
                    <div className='cover_img' style={{ 'background': `url(${(UserInfo.banner_photo) ? img_view(UserInfo.banner_photo) : 'https://dummyimage.com/720x405/000/fff'})` }}>

                    </div>
                    <div className='user_info'>
                        <div className='text-center user_img'>
                            {!UserInfo.photo && <img src="https://dummyimage.com/400x400/000/fff" />}
                            {UserInfo.photo && <img src={img_view(UserInfo.photo)} />}
                        </div>
                        <div className='text-center'>
                            <h5 className='m-0 mb-2'>{UserInfo.name}</h5>
                            <p className='mb-2'>{UserInfo.heading} - {UserInfo.company_name}</p>
                            {UserInfo.location && <p className='mb-2'>{UserInfo.location}</p>}
                            <button type="button" onClick={save_to_contact} className='btn btn-primary c-btn-primary'> Save To Contact</button>
                        </div>
                    </div>
                    <hr />
                    <div className='tab-nav'>
                        {UserInfo.show_contact_form_tab > 0 && <a href="javascript:;" onClick={() => { SetActiveTab('home') }} className={ActiveTab == 'home' ? "active" : ""}><b>Home</b></a>}
                        {UserInfo.show_contact_form_tab > 0 && <a href="javascript:;" onClick={() => { SetActiveTab('contact-us'); SetInquirySendSuccess(false); }} className={ActiveTab == 'contact-us' ? "active" : ""}><b>Contact Us</b></a>}
                    </div>
                    <div className='pl-3 pr-3 pt-2 pb-2'>
                        {ActiveTab == 'home' && <div>
                            {UserInfo.about && <><h6><b>About</b></h6>
                                <div dangerouslySetInnerHTML={{ __html: UserInfo.about }} ></div>
                                <hr /></>}
                            {UserInfo.user_links && Object.entries(UserInfo.user_links).map(([key, category]) => (
                                <>
                                    <h6 className='mb-3'><b>{category.category}</b></h6>
                                    <ul className='link-list'>
                                        {Object.entries(category.links).map(([key, value]) => (
                                            <li>
                                                <a href='javascript:;' onClick={link_clikc.bind(this, value)}>
                                                    <div className='img'>
                                                        <img src={img_view(value.icon)} alt={value.name} />
                                                    </div>
                                                    <div style={{ 'float': 'left' }}>
                                                        {(value.name == 'Phone' || value.name == 'Email' || value.name == 'Skype' || value.name == 'Signal') && <h6 className='m-0'>{value.value}</h6>}
                                                        {(value.name != 'Phone' && value.name != 'Email' && value.name != 'Skype' && value.name != 'Signal') && <h6 className='m-0'>{value.name}</h6>}
                                                        <p className='m-0'><small>{value.title}</small></p>
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ))}
                        </div>}

                        {ActiveTab == 'contact-us' && <div>
                            {!InquirySendSuccess && <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label className="form-label">Name *</label>
                                    <input name="name" value={InquiryForm.name} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('name', InquiryForm.name, 'required')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Email *</label>
                                    <input name="email" value={InquiryForm.email} onChange={handleInputChange} className="form-control" type="text" />
                                    {simpleValidator.current.message('email', InquiryForm.email, 'required|email')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Mobile Number *</label>
                                    <input name="mobile_number" value={InquiryForm.mobile_number} onChange={handleInputChange} className="form-control" type="text" maxLength={12} />
                                    {simpleValidator.current.message('mobile number', InquiryForm.mobile_number, 'required|numeric')}
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">How can we help you? *</label>
                                    <textarea name="message" value={InquiryForm.message} onChange={handleInputChange} className="form-control" rows={5} style={{ "resize": "none" }} />
                                    {simpleValidator.current.message('message', InquiryForm.message, 'required')}
                                </div>
                                <div className="text-center mt-4">
                                    <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                </div>
                            </form>}
                            {InquirySendSuccess && <div className='text-center pt-5 pb-5'>
                                <svg width="50px" style={{ 'color': '#10b981' }} xmlns="http://www.w3.org/2000/svg" class="mb-4 h-10 w-10 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <h4 className='mb-3'>Thank you!</h4>
                                <p className='mb-0'>Your message has been successfully sent.</p><p> We will contact you very soon!</p>
                                <span className='pointer' onClick={() => { SetActiveTab('home') }}><b>Back To Home</b></span>
                            </div>}
                        </div>}
                        <hr />
                        <p className='text-center'>© 2023 TapGrid. All Rights Reserved. Create your own page</p>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(Services)
