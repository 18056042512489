import React, { useState, useEffect } from 'react';
import { withRouter,Link } from "react-router-dom";
import Details from "./Details";
import LinkPage from "./Link";
import ThemePage from "./Theme";
import ContactForm from "./ContactForm";

const Card = props => {
    const [Tab, SeTab] = useState(1);

    useEffect(() => {
        async function get_detaile() {
        }
        get_detaile();
    }, [])

    
    const next_back = (page) => {
        SeTab(page);
    }

    return (
        <>  
            <div className='row'>
                <div className='col-sm-12'>
                    <ul className='profile_menu'>
                        <li><Link href='#' onClick={next_back.bind(this,1)} className={`banner ${Tab === 1 ? "active" : ""}`}>Details</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,2)} className={`banner ${Tab === 2 ? "active" : ""}`}>Link</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,3)} className={`banner ${Tab === 3 ? "active" : ""}`}>Theme</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,4)} className={`banner ${Tab === 4 ? "active" : ""}`}>Contact Form</Link></li>
                        {/* <li><Link href='#' onClick={next_back.bind(this,4)} className={`banner ${Tab === 4 ? "active" : ""}`}>Portfolio</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,5)} className={`banner ${Tab === 5 ? "active" : ""}`}>Services</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,6)} className={`banner ${Tab === 6 ? "active" : ""}`}>Action Buttons</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,7)} className={`banner ${Tab === 7 ? "active" : ""}`}>Education</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,8)} className={`banner ${Tab === 8 ? "active" : ""}`}>Experience</Link></li>
                        <li><Link href='#' onClick={next_back.bind(this,10)} className={`banner ${Tab === 10 ? "active" : ""}`}>Settings</Link></li> */}
                    </ul>
                </div>
            </div>
            <hr style={{'marginLeft': '-35px','marginRight': '-35px'}}/>
            <div className='row'>
                <div className='col-sm-8'>
                    {Tab == 1 && <Details />}
                    {Tab == 2 && <LinkPage />}
                    {Tab == 3 && <ThemePage />}
                    {Tab == 4 && <ContactForm />}
                </div>
                <div className='col-sm-4'>

                </div>
            </div>
          
        </>
    );
}

export default withRouter(Card);
