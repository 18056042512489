import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
// import { get } from '../../helpers/api_helper';
// import { GET_DISTRIBUTOR_DASHBOARD } from '../../helpers/url_helper';
// import { toast } from 'react-toastify';
import Logo from './../../assets/images/logo.png';
const Order = props => {
    // const [totalBalance, SetTotalBalance] = useState(0);
    // const [totalRetailer, SetTotalRetailer] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            // await get_service();
        }
        get_detaile();
    }, [])

    // const get_service = async () => {
    //     var res_data = await get(GET_DISTRIBUTOR_DASHBOARD, {});
    //     if (res_data.status) {
    //         SetTotalBalance(res_data.data.total_balance)
    //         SetTotalRetailer(res_data.data.total_retailer)
    //     } else {
    //         toast.error(res_data.message);
    //     }
    // }

    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Card Order</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Order Id</td>
                                            <td>Date</td>
                                            <td>User Name</td>
                                            <td>Qty</td>
                                            <td>City</td>
                                            <td>Country</td>
                                            <td>Order Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>202200001</td>
                                            <td>21-07-22 12:00</td>
                                            <td>Sagar Patle</td>
                                            <td>3</td>
                                            <td>Rajkot</td>
                                            <td>India</td>
                                            <td><span className='text-warning'>Pending</span></td>
                                            <td>
                                                <button data-toggle="modal" data-target="#exampleModal" className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" ><i className="fa fa-eye" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>202200001</td>
                                            <td>21-07-22 12:00</td>
                                            <td>Sagar Patle</td>
                                            <td>3</td>
                                            <td>Rajkot</td>
                                            <td>India</td>
                                            <td><span className='text-warning'>Pending</span></td>
                                            <td>
                                                <button data-toggle="modal" data-target="#exampleModal" className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" ><i className="fa fa-eye" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>202200001</td>
                                            <td>21-07-22 12:00</td>
                                            <td>Sagar Patle</td>
                                            <td>3</td>
                                            <td>Rajkot</td>
                                            <td>India</td>
                                            <td><span className='text-warning'>Pending</span></td>
                                            <td>
                                                <button data-toggle="modal" data-target="#exampleModal" className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" ><i className="fa fa-eye" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>202200001</td>
                                            <td>21-07-22 12:00</td>
                                            <td>Sagar Patle</td>
                                            <td>3</td>
                                            <td>Rajkot</td>
                                            <td>India</td>
                                            <td><span className='text-warning'>Pending</span></td>
                                            <td>
                                                <button data-toggle="modal" data-target="#exampleModal" className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" ><i className="fa fa-eye" style={{ 'font-size': '12px' }} aria-hidden="true"></i></button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Order Detaile</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='billing'>
                                <div className='row align-items-center'>
                                    <div className='col-sm-1'>
                                        <img src={Logo}></img>
                                    </div>
                                    <div className='col-sm-11'>
                                        <h5>TapGrid Pvt Ltd.</h5>
                                        <p>2901 N Ashton Blvd Ste 300 <br />Lehi, UT 84043 United States</p>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-sm-4'>
                                        <div className='row'>
                                            <div className='col-sm-6'><b>Order Date : </b></div>
                                            <div className='col-sm-6'>22-07-2022</div>
                                            <div className='col-sm-6'><b>Order No : </b></div>
                                            <div className='col-sm-6'>202200001</div>
                                            <div className='col-sm-6'><b>Order Status : </b></div>
                                            <div className='col-sm-6'><span className='text-warning'>Pending</span></div>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='row'>
                                            <div className='col-sm-6'><b>Email : </b></div>
                                            <div className='col-sm-6'>xyz@gmail.com</div>
                                            <div className='col-sm-6'><b>Phone No : </b></div>
                                            <div className='col-sm-6'>1234567890</div>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <b>Shipping Address : </b> <br />
                                                <p className='mb-0'>Jakasaniya sagar</p>
                                                <p className='mb-0'>2901 N Ashton Blvd Ste 300</p>
                                                <p className='mb-0'>Rajkot Gujarat India 123456</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className='row mt-3'>
                                    <div className='col-sm-4'>
                                        <img src='https://wisie.co/wp-content/uploads/2021/12/Business-Card-Logos-Metal-Black-koroni.png' style={{'max-width': '200px'}} alt='card' />
                                    </div>
                                    <div className='col-sm-8'>
                                        <div className='row'>

                                            <div className='col-sm-6'>
                                                <b>Name : </b> <br />
                                                <p className="mb-2">Jakasaniya sagar j</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Designation : </b> <br />
                                                <p className="mb-2">CEO of Company Co</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Email : </b> <br />
                                                <p className="mb-2">jakasaniyasagar99@gmail</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Phone No : </b> <br />
                                                <p className="mb-2">12345 67890</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className='row mt-3'>
                                    <div className='col-sm-4'>
                                        <img src='https://wisie.co/wp-content/uploads/2021/12/Business-Card-Logos-Metal-Black-koroni.png' style={{'max-width': '200px'}} alt='card' />
                                    </div>
                                    <div className='col-sm-8'>
                                        <div className='row'>

                                            <div className='col-sm-6'>
                                                <b>Name : </b> <br />
                                                <p className="mb-2">Jakasaniya sagar j</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Designation : </b> <br />
                                                <p className="mb-2">CEO of Company Co</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Email : </b> <br />
                                                <p className="mb-2">jakasaniyasagar99@gmail</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Phone No : </b> <br />
                                                <p className="mb-2">12345 67890</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className='row mt-3'>
                                    <div className='col-sm-4'>
                                        <img src='https://wisie.co/wp-content/uploads/2021/12/Business-Card-Logos-Metal-Black-koroni.png' style={{'max-width': '200px'}} alt='card' />
                                    </div>
                                    <div className='col-sm-8'>
                                        <div className='row'>

                                            <div className='col-sm-6'>
                                                <b>Name : </b> <br />
                                                <p className="mb-2">Jakasaniya sagar j</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Designation : </b> <br />
                                                <p className="mb-2">CEO of Company Co</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Email : </b> <br />
                                                <p className="mb-2">jakasaniyasagar99@gmail</p>
                                            </div>
                                            <div className='col-sm-6'>
                                                <b>Phone No : </b> <br />
                                                <p className="mb-2">12345 67890</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className='row mt-3'>
                                    <div className='col-sm-12'>
                                        <h6>Order Shipping Details</h6>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div>
                                            <b>Shipping Company Name : </b> <br />
                                            <p className="mb-2">DHL</p>
                                        </div>
                                        <div>
                                            <b>Tracking Id : </b> <br />
                                            <p className="mb-2">123456</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <table className='table table-striped table-small'>
                                            <thead>
                                                <tr>
                                                    <th>Sr.</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Order Placed</td>
                                                    <td>20-07-2022 10:00:00</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Order Confirmed</td>
                                                    <td>21-07-2022 10:00:00</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Shipped</td>
                                                    <td>22-07-2022 10:00:00</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Out For Delivery</td>
                                                    <td>23-07-2022 10:00:00</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Delivered</td>
                                                    <td>24-07-2022 10:00:00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Order);
