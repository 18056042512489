import React, { useState, useRef, useEffect } from 'react';
import { withRouter, useHistory, Link } from "react-router-dom";
import { post, get,del } from '../../../helpers/api_helper';
import { GET_LINKS, POST_ADD_EDIT_LINK, GET_USER_LINK,DELETE_USER_LINK } from '../../../helpers/url_helper';
import { img_view } from '../../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';


const Details = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [link_inputs, setLinkInputs] = useState({ user_link_id: '', user_theme_id: props.match.params.id, title: '', value: '', link_id: '', link_name: '' });
    const [LinkListe, setLinkListe] = useState([]);
    const [UserLinkListe, setUserLinkListe] = useState([]);
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            await getUserLink();
            var res_data = await get(GET_LINKS);
            if (res_data.status) {
                setLinkListe(res_data.data.category_list);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [props.match.params.id])


    const getUserLink = async (event) => {
        var res_data = await post(GET_USER_LINK, { user_theme_id: props.match.params.id });
        if (res_data.status) {
            setUserLinkListe(res_data.data.link_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const remove_link = async (id) => {
        var res_data = await del(DELETE_USER_LINK+'?id='+id);
        if (res_data.status) {
            toast.success(res_data.message);
            getUserLink()
        } else {
            toast.error(res_data.message);
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_LINK, link_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                window.$('#AddLinkModal').modal('hide');
                getUserLink();
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setLinkInputs(inputs => ({ ...link_inputs, [event.target.name]: event.target.value }));
    }

    const add_link = (detail) => {
        setLinkInputs({ user_link_id: '', user_theme_id: props.match.params.id, title: '', value: '', link_id: detail.link_id, link_name: detail.name });
    }

    const edit_link = (detail) => {
        setLinkInputs({ user_link_id: detail.user_link_id, user_theme_id: props.match.params.id, title: detail.title, value: detail.value, link_id: detail.link_id, link_name: detail.name });
    }

    return (
        <>
            <div className='row mt-3'>
                <div className='col-sm-5'>
                    <div className='card'>
                        <div className="card-header">
                            <h5 className='m-0'>Preview</h5>
                        </div>
                        <div className='card-body'>
                            <div className='link_list'>
                                {UserLinkListe && Object.entries(UserLinkListe).map(([key, category]) => (
                                    <div className='box' key={key}>
                                        <img src={img_view(category.icon)} />
                                        <div className='text-box'>
                                            <p className='mb-0'>{category.value}</p>
                                            <small>{category.name}</small>
                                        </div>
                                        <div className='action'>
                                            <Link to="#" className='mr-2' onClick={edit_link.bind(this,category)} data-toggle="modal" data-target="#AddLinkModal"><i className="fa fa-pencil mr-2" aria-hidden="true"></i></Link>
                                            <Link to="#" onClick={remove_link.bind(this,category.user_link_id)}><i className="fa fa-trash" aria-hidden="true"></i></Link>
                                        </div>
                                    </div>
                                ))}
                               {!UserLinkListe.length && <div>
                                    <p className='text-center'>No items found.</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-7'>
                    <div className='card'>
                        <div className="card-header">
                            <h5 className='m-0'>Add more links & info</h5>
                        </div>
                        <div className='card-body'>
                            {LinkListe && Object.entries(LinkListe).map(([key, category]) => (
                                <div key={key}>
                                    <h6 className='mb-4'><b>{category.category}</b></h6>
                                    <div className='link_list_box'>
                                        {category.link && Object.entries(category.link).map(([key, value]) => (
                                            <div className='box' onClick={add_link.bind(this, value)} key={key} data-toggle="modal" data-target="#AddLinkModal">
                                                <img src={img_view(value.icon)} alt={value.name} />
                                                <p className='mt-2'>{value.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="AddLinkModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header pt-2 pb-2">
                            <h5 className="modal-title mt-0" id="exampleModalLabel">
                                {!link_inputs.user_link_id && <span>Add </span>}
                                {link_inputs.user_link_id && <span>Update </span>}
                                {link_inputs.link_name}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Display Text *</label>
                                            <input name="title" className="form-control" value={link_inputs.title} onChange={handleInputChange} type="text" />
                                            {simpleValidator.current.message('title', link_inputs.title, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Value *</label>
                                            <input name="value" className="form-control" value={link_inputs.value} onChange={handleInputChange} type="text" />
                                            {simpleValidator.current.message('value', link_inputs.value, 'required')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={ButtonDisabled} className="btn btn-secondary c-btn-danger btn-sm" data-dismiss="modal">Close</button>
                                <button type="submit" disabled={ButtonDisabled} className="btn btn-primary c-btn-primary btn-sm"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Details);
