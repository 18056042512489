import React, { useState, useEffect } from 'react';
import Logo from './../assets/images/logo.png';
import { is_login } from './../helpers/Helper.js';
import { Link, NavLink } from "react-router-dom";

export default function Header(props) {
    const [totalQty, SettotalQty] = useState(0);
    useEffect(() => {
        async function get_detaile() {
            var cart_detile = sessionStorage.getItem('cart');
            if (cart_detile) {
                cart_detile = JSON.parse(cart_detile);
                SettotalQty(cart_detile.qty);
            }else{
                SettotalQty(0);
            }
        }
        get_detaile();
    })

    return (
        <header>
            <div className="min-header">
                <div className="container">
                    <div className="row">
                        <div className="col-7">
                            <i className="fa fa-phone mr-2" aria-hidden="true"></i> Call Us: 01244 355347
                        </div>
                        <div className="col-5 text-right social-link">
                            <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                            <a href="#" className="pr-0"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="heade">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} alt="TapGrid" />
                        </Link>
                        <button className="navbar-toggler navbar-toggler-right collapsed" type="button" data-toggle="collapse"
                            data-target="#Navigation" aria-controls="Navigation" aria-expanded="false"
                            aria-label="Toggle navigation"><i className="fa fa-bars" aria-hidden="true"></i></button>
                        <div className="collapse navbar-collapse" id="Navigation">
                            <ul className="navbar-nav m-auto">
                                <li className="nav-item"><NavLink className="nav-link" exact to="/">Home</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" exact to="/pricing">Pricing</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" exact to="/">Features</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" exact to="/">Testimonial</NavLink></li>
                                <li className="nav-item"><NavLink className="nav-link" exact to="/">Contact</NavLink></li>
                            </ul>
                        </div>
                        <div className="card-menu">
                            {!is_login() && <Link to="/login"> <i className="fa fa-user-o mr-2" aria-hidden="true"></i> Log in</Link>}
                            {is_login() && <Link to="/dashboard"> <i className="fa fa-user-o mr-2" aria-hidden="true"></i> Profile</Link>}
                            <Link to="/cart" className='ml-3'>
                                <i className="fa fa-shopping-bag mr-2" aria-hidden="true"></i>
                                <span className="count">{totalQty}</span>
                            </Link>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}