import React, { useState, useEffect } from 'react';
import { Link, useHistory, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { GET_CART } from '../../helpers/url_helper';

const Services = props => {
    let history = useHistory();

    const [cardDetile, SetcardDetile] = useState({});

    useEffect(() => {
        async function get_detaile() {
            get_cart();
        }
        get_detaile();
    }, [])

    const get_cart = async () => {
        var id = 0;
        var qty = 0;
        var cart_detile = sessionStorage.getItem('cart');
        if (cart_detile) {
            cart_detile = JSON.parse(cart_detile);
            id = cart_detile.id;
            qty = cart_detile.qty;
        }
        var res_data = await post(GET_CART, { id: id, qty: qty });
        if (res_data.status) {
            SetcardDetile(res_data.data);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const qtyChange = (type) => {
        var cart_detile = sessionStorage.getItem('cart');
        cart_detile = JSON.parse(cart_detile);
        if (type === 'plus') {
            cart_detile.qty = (parseInt(cart_detile.qty) + 1);
        } else {
            cart_detile.qty = (parseInt(cart_detile.qty) - 1);
        }
        if (cart_detile.qty > 100) {
            return true;
        }
        sessionStorage.setItem('cart', JSON.stringify(cart_detile));
        get_cart();
    }

    const removeItem = () => {
        var cart_detile = sessionStorage.removeItem('cart');
        get_cart();
    }

    return (
        <>
            <section className="section cart">
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h1 className="font-family-bold text-uppercase mt-0">Cart</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 mb-3">
                            <div className='product_list'>
                                {cardDetile.item && Object.entries(cardDetile.item).map(([key, value]) => (
                                    <div className='product_box' key={key}>
                                        <div className='row align-items-center'>
                                            <div className='col-sm-1'>
                                                <i className="fa fa-database icone" aria-hidden="true"></i>
                                            </div>
                                            <div className='col-sm-6'>
                                                <h6 className="font-family-bold text-uppercase">
                                                    {value.title}
                                                </h6>
                                                <span className='text-danger cursor-pointer' data-toggle="modal" data-target="#exampleModalCenter">Remove</span>
                                            </div>
                                            <div className='col-sm-2'>
                                                <h6 className="font-family-bold text-uppercase"> ${value.price}</h6>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className='qty_box'>
                                                    <button type='button' onClick={qtyChange.bind(this, 'minus')} disabled={(value.qty == 1) ? true : false} className='minus'>-</button>
                                                    <input type='text' value={value.qty} readOnly />
                                                    <button type='button' onClick={qtyChange.bind(this, 'plus')} className='plus'>+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {!cardDetile?.item?.length && <div className='text-center pt-5'>
                                    <p>Your cart is empty!</p>
                                </div>}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className='row'>
                                <div className='col-sm-6'>Sub Total</div>
                                <div className='col-sm-6 text-right'>${cardDetile.sub_total}</div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-sm-6'>Tax</div>
                                <div className='col-sm-6 text-right'>${cardDetile.tax}</div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-sm-6'><h6 className="font-family-bold text-uppercase">Order total</h6></div>
                                <div className='col-sm-6 text-right'><h6 className="font-family-bold text-uppercase">${cardDetile.total}</h6></div>
                            </div>
                            {cardDetile?.item?.length > 0 && <div className='row mt-4'>
                                <div className='col-sm-12'>
                                    <Link to="/check-out" className='btn cbtn-primary w-100 pt-2 pb-2' >Check Out</Link>
                                </div>
                            </div>}
                        </div>
                    </div>

                </div>
            </section>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <h5>Are you sure that you want to remove this Item?</h5>
                            <div className='text-center mt-4'>
                                <button type="button" className="btn cbtn-danger mr-4" data-dismiss="modal">NO</button>
                                <button onClick={removeItem.bind(this)} type="button" data-dismiss="modal" className="btn cbtn-primary">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default withRouter(Services)
