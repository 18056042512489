
const getToken = () => {
    return sessionStorage.getItem('web_token') || null;
}

const getUserDetail = () => {
    return JSON.parse(sessionStorage.getItem('web_user_detail'));
}

const getUserId = () => {
    return (sessionStorage.getItem('web_user_detail'))? JSON.parse(sessionStorage.getItem('web_user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if(sessionStorage.getItem('web_user_detail')){
        return JSON.parse(sessionStorage.getItem('web_user_detail'))['user_role'];
    }else{
        return 0;
    }
}

const removeUserSession = () => {
    sessionStorage.removeItem('web_token');
    sessionStorage.removeItem('web_user_detail');
}

const setUserSession = (token, user) => {
    sessionStorage.setItem('web_token', token);
    sessionStorage.setItem('web_user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (sessionStorage.getItem('web_token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const img_view = (img) => {
    return process.env.REACT_APP_IMG_URL+'uploads/'+img
}

export { img_view,amountFormat, getUserType,getToken, removeUserSession, setUserSession, is_login,getUserDetail, getUserId };