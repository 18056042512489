import React from 'react';
import { NavLink } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
export default function Header() {

    return (
        <div className='left_nav_bar'>
            <ul className='sidebar-menu'>
                <li>
                    <NavLink exact to="/dashboard" className="has-arrow">
                        <FeatherIcon icon="home" width="22" />
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/profile" className="has-arrow">
                        <i className="fa fa-address-card-o mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Profile</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/leads" className="has-arrow">
                        <i className="fa fa-commenting-o mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Leads</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/cardes" >
                        <i className="fa fa-credit-card mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Card</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/user-cart" className="has-arrow">
                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Cart</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/order" className="has-arrow">
                        <i className="fa fa-shopping-cart mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Order History</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/subscription" className="has-arrow">
                        <i className="fa fa-product-hunt mr-2" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Subscription</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink exact to="/payment" className="has-arrow">
                        <i className="fa fa-usd mr-2 ml-1" aria-hidden="true" style={{ 'fontSize': '19px', 'marginTop': '5px' }}></i>
                        <span>Payment</span>
                    </NavLink>
                </li>
                <li>
                    <a href="#SettingSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >
                        <FeatherIcon icon="settings" width="22" />
                        <span style={{ 'marginTop': '2px' }}>Setting</span>
                    </a>
                    <ul className="collapse list-unstyled" id="SettingSubmenu">
                        <li>
                            {/* <NavLink to="/profile">Profile</NavLink> */}
                            <NavLink to="/change-password">Change Password</NavLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}