import React from 'react';
import { Link, withRouter } from "react-router-dom";

const Services = props => {
    return (
        <>
            <section className="section payment-success">
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <img src='https://npm.works/wp-content/uploads/2022/01/02-lottie-tick-01-instant-2.gif'/>
                            <h3 className="font-family-bold text-uppercase mt-0 mb-4">Payment Successful</h3>
                            <p className='mb-1'>Order No. : 1234567890</p>
                            <p className='mb-3'>Time : 20 july 20222 19:17</p>
                            <p className='mb-5'>Thank you! Your payment is complete</p>

                            <Link to="/" className='btn cbtn-primary'>Go to Home</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(Services)
