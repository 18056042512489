import React from 'react';

// component
import Header from '../component/header_web';
import Footer from '../component/footer_web';
// import '../assets/css/style.css'

function Default({ children }) {
    window.scrollTo(0, 0);

    return (
        <>  
            <Header />
                {children}
            <Footer />
        </>
    );
}

export default Default;
