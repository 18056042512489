
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import Logo from './../assets/images/logo.png';
import FeatherIcon from "feather-icons-react";

export default function Header(props) {
    const [TotalBalance, setTotalBalance] = useState(null);

    useEffect(() => {
        async function get_detaile() {
        }
        get_detaile();
    }, [])


    const handleLangChange = () => {
        props.onChangeHandleMenu();
    }

    return (
        <div className='nav_bar'>
            <div className='nav_bar_left text-center'>
                <Link to="/">
                    <img alt="TapGrid" src={Logo} width='40px' />
                </Link>
            </div>
            <div className='nav_bar_right'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <a href='#' onClick={handleLangChange}><FeatherIcon icon="menu" className="mt-10" /></a>
                        </div>
                        <div className='col-sm-6 text-right'>
                            <Link to="/logout" className='ml-4' ><FeatherIcon icon="power" width="25" style={{ 'marginTop': '5px' }} /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}