import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
// import { get } from '../../helpers/api_helper';
// import { GET_DISTRIBUTOR_DASHBOARD } from '../../helpers/url_helper';
// import { toast } from 'react-toastify';

const Dashboard = props => {
    // const [totalBalance, SetTotalBalance] = useState(0);
    // const [totalRetailer, SetTotalRetailer] = useState(0);

    useEffect(() => {
        async function get_detaile() { 
            // await get_service();
        }
        get_detaile();
    }, [])

    // const get_service = async () => {
    //     var res_data = await get(GET_DISTRIBUTOR_DASHBOARD, {});
    //     if (res_data.status) {
    //         SetTotalBalance(res_data.data.total_balance)
    //         SetTotalRetailer(res_data.data.total_retailer)
    //     } else {
    //         toast.error(res_data.message);
    //     }
    // }

    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <h5>Welcome To TapGrid</h5>
                </div>
            </div>
            <div className='row mt-15'>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h5>1/10</h5>
                            <h6>Total Cards</h6>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h5>1/10</h5>
                            <h6>Total Licence</h6>
                        </div>
                    </div>
                </div>
                {/* <div className='col-sm-12 col-md-6 col-lg-3 mb-3'>
                    <div className='card'>
                        <div className="card-body text-center">
                            <h6>Total Commission</h6>
                            <h6>10</h6>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default withRouter(Dashboard);
