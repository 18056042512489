import React from 'react';
import Logo from './../assets/images/logo.png';
import { Link } from "react-router-dom";


export default function Header(props) {

    return (
        <footer className="footer">
            <div className="container pb-3">
                <div className="row">
                    <div className="col-md-4">
                        <h5 className="text-footer font-family-bold mb-3"> Your One tap Smart Business Card</h5>
                        <p className="text-muted  mb-2">
                            Share you Contact information and Social Media Profiles at just One Tap!
                        </p>
                        <div className="mb-3 mt-3">
                            <img src={Logo} alt="" className="img-fluid mx-auto logo-size" />
                        </div>
                        <div className="col-5 social-link m-0 p-0">
                            <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                            <a href="#" className="pr-0"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h5 className="text-footer font-family-bold mb-3"> Learn & Get Help</h5>
                        <div className="row">
                            <div className="col-5">
                                <ul className="footer-link">
                                    <li><Link to="/">Affiliate</Link></li>
                                    <li><Link to="/">Support</Link></li>
                                    <li><Link to="/">FAQS</Link></li>
                                    <li><Link to="/">Shipping</Link></li>
                                    <li><Link to="/">Return</Link></li>
                                    <li><Link to="/">Privacy</Link></li>
                                </ul>
                            </div>
                            <div className="col-7">
                                <ul className="footer-link">
                                    <li><Link to="/">Terms</Link></li>
                                    <li><Link to="/">Contact</Link></li>
                                    <li><Link to="/">Compatible Devices</Link></li>
                                    <li><Link to="/">Copyright decleartion</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h5 className="text-footer font-family-bold mb-3"> Newsletter</h5>
                        <form className="newsletter-form">
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="First Name" />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Last Name" />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Your Mail" />
                            </div>
                            <div className="form-group">
                                <button className="btn cbtn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="footer-reg">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <p className="mb-0">© 2022 TapGrid. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}