import React, { useState, useRef } from 'react';
import PropTypes from "prop-types"
import { useHistory, withRouter, Link } from "react-router-dom";
import { post } from '../../helpers/api_helper';
import { POST_FORGOT_PASSWORD, POST_RESET_PASSWORD } from '../../helpers/url_helper';
import { setUserSession, getToken } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

const ForgetPassword = props => {
    let history = useHistory();
    if (getToken()) {
        history.push("/dashboard");
    }

    const simpleValidator = useRef(new SimpleReactValidator());
    const OtpValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [otp_screen, SetOTP_screen] = useState(false);
    const [form_inputs, setInputs] = useState({ email: '', password: '', c_password: '', otp: '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_FORGOT_PASSWORD, { 'email': form_inputs.email }, {});
            if (res_data.status) {
                SetOTP_screen(true);
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const verifyOtp = async (event) => {
        event.preventDefault();
        const formValid = OtpValidator.current.allValid()
        if (!formValid) {
            OtpValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_RESET_PASSWORD, form_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                history.push("/dashboard");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (
        <>
            <section className="auth_section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="bg-white col-lg-10">
                            <div className="col-sm-12 align-items-center">
                                <div className="row">
                                    <div className="col-sm-6 login_img">
                                        <div className="p-5 m-4">
                                            <h1 className="text-white mt-5">Welcome to TapGrid!</h1>
                                            <h5 className="mt-4">Tapgrid is created for smart business card</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="p-4">
                                            <h3 className="mb-4 mt-3 font-family-bold">Forgot Password</h3>
                                            {!otp_screen &&
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group mb-3">
                                                        <label>Email</label>
                                                        <input name="email" className="form-control" value={form_inputs.email} onChange={handleInputChange} placeholder="Email address" type="text" />
                                                        {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                                    </div>
                                                    <div className="form-group mt-4">
                                                        <button disabled={ButtonDisabled} type="submit" className="form-control btn cbtn-black"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Reset </button>
                                                    </div>
                                                </form>}
                                            {otp_screen &&
                                                <form onSubmit={verifyOtp}>
                                                    <div className="form-group">
                                                        <label>Otp</label>
                                                        <input name="otp" className="form-control" maxLength="6" value={form_inputs.otp} onChange={handleInputChange} placeholder="Enter OTP" type="text" />
                                                        {OtpValidator.current.message('otp', form_inputs.otp, 'required|numeric|min:6')}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label">Password</label>
                                                        <input name="password" className="form-control" value={form_inputs.password} onChange={handleInputChange} placeholder="Enter New Password" type="password" />
                                                        {OtpValidator.current.message('password', form_inputs.password, 'required')}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label">Confirm Password</label>
                                                        <input name="c_password" className="form-control" value={form_inputs.c_password} onChange={handleInputChange} placeholder="Enter Confirm Password" type="password" />
                                                        {OtpValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                                    </div>
                                                    <div className="form-group mt-4">
                                                        <button disabled={ButtonDisabled} type="submit" className="form-control btn cbtn-black"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Change </button>
                                                    </div>
                                                </form>}
                                            <p className="text-center">Not a member? <Link to="/login">Sign In</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(ForgetPassword)
ForgetPassword.propTypes = {
    history: PropTypes.object,
}
