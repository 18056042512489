import React from 'react';
import { Link, withRouter } from "react-router-dom";

const Services = props => {
    return (
        <>
            <section className="section payment-failed">
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <img src='https://static.wixstatic.com/media/ac2dcc_cf7e6cdf84a2426dba33892e0c3d4158~mv2.gif'/>
                            <h3 className="font-family-bold text-uppercase mt-0 mb-4">Payment transfer Failed!</h3>
                            <p className='mb-1'>Order No. : 1234567890</p>
                            <p className='mb-4'>Time : 20 july 20222 19:17</p>
                            <p className='note'>Don't worry your money safe! if money was debited from your account, it will be order status update in 5-7 working days</p>
                            <Link to="/" className='btn cbtn-primary mt-4'>Go to Home</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default withRouter(Services)
