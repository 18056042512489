import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
// import { get } from '../../helpers/api_helper';
// import { GET_DISTRIBUTOR_DASHBOARD } from '../../helpers/url_helper';
// import { toast } from 'react-toastify';

const Card = props => {

    const [step, Setstep] = useState(1);
    // const [totalRetailer, SetTotalRetailer] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            // await get_service();
        }
        get_detaile();
    }, [])

    const next_back = (page) => {
        Setstep(page);
    }


    return (
        <>
            <div className='row'>
                <div className='col-sm-12 text-center'>
                    <h4>Progress Bar Cart Order</h4>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-sm-2'></div>
                <div className='col-sm-8'>
                    <div className="track">
                        <div className={"first step " + (step >= 1 ? 'active' : '')} > <span className="icon"> 1 </span> <span className="text">Choose Card</span> </div>
                        <div className={"step " + (step >= 2 ? 'active' : '')}> <span className="icon"> 2 </span> <span className="text"> Shipping Detaile</span> </div>
                        <div className={"step last " + (step >= 3 ? 'active' : '')}> <span className="icon"> 3 </span> <span className="text"> Confirm </span> </div>
                    </div>
                </div>
            </div>

            {/* select card */}
            {step === 1 && <div className='row mt-15'>
                <div className='col-sm-2 pt-2'></div>
                <div className='col-sm-8 text-center mb-4'>
                    <h5>Selected Card</h5>
                </div>
                <div className='col-sm-2 text-right'>
                    <button onClick={next_back.bind(this, 2)} className='btn c-btn-primary btn-sm text-white' type='button'>{`Next >`}</button>
                </div>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <span className='remove-btn' ><i className="fa fa-times" aria-hidden="true"></i></span>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <span className='remove-btn' ><i className="fa fa-times" aria-hidden="true"></i></span>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-6 col-xl-4 mb-4'>
                    <div className="card p-3">
                        <div className='row mb-3'>
                            <span className='remove-btn' ><i className="fa fa-times" aria-hidden="true"></i></span>
                            <div className='col-8'>
                                <h5>Jakasaniya sagar J</h5>
                                <p>CEO of Company Co</p>
                            </div>
                            <div className='col-4'>
                                <img alt="qrcode" src="https://raw.githubusercontent.com/nkmk/python-snippets/b8f38d902b258efdf47dbf31d0aa63b96bf37274/notebook/data/dst/qrcode_test.png" width="100px" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10'>
                                <p className='mb-0'><i className="fa fa-phone mr-2" aria-hidden="true"></i> 12345 67890</p>
                                <p className='mb-0'><i className="fa fa-envelope-o mr-2" aria-hidden="true"></i> jakasaniyasagar99@gmail.com</p>
                            </div>
                            <div className='col-2 '>
                                <p className='mb-0'><i className="fa fa-wifi nfc" aria-hidden="true"></i></p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {/* Card Details */}
            {step === 2 && <div className='row mt-15'>
                <div className='col-sm-2 pt-2'>
                    <button onClick={next_back.bind(this, 1)} className='btn c-btn-primary btn-sm text-white' type='button'>{`< Back`}</button>
                </div>
                <div className='col-sm-8 text-center mb-4'>
                    <h5>Shipping Detaile</h5>
                </div>
                <div className='col-sm-2 text-right'>
                </div>
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="form-group">
                                <label>Country / region</label>
                                <select className="form-control" >
                                    <option value="">Select Country</option>
                                    <option value="">Country 1</option>
                                    <option value="">Country 2</option>
                                    <option value="">Country 3</option>
                                    <option value="">Country 4</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className="form-group">
                                <label>Full Name</label>
                                <input type="text" className="form-control" placeholder='Enter You Full Name' />
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className="form-group">
                                <label>Address</label>
                                <input type="text" className="form-control" placeholder='Enter You Address' />
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label>City</label>
                                <input type="text" className="form-control" placeholder='Enter You City' />
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label>State</label>
                                <select className="form-control" >
                                    <option value="">Select State</option>
                                    <option value="">State 1</option>
                                    <option value="">State 2</option>
                                    <option value="">State 3</option>
                                    <option value="">State 4</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className="form-group">
                                <label>Pin Code</label>
                                <input type="text" className="form-control" placeholder='Enter You Pin Code' />
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input type="text" className="form-control" placeholder='Enter You Email Address' />
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input type="text" className="form-control" placeholder='Enter You Phone Number' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 text-center mt-3'>
                    <button onClick={next_back.bind(this, 3)} className='btn c-btn-primary btn-sm text-white' type='button'>Confirm Shipping</button>
                </div>
            </div>}

            {/* Confirm */}
            {step === 3 && <div className='row mt-15'>

                <div className='col-sm-12 text-center mt-3'>
                    <img src='https://npm.works/wp-content/uploads/2022/01/02-lottie-tick-01-instant-2.gif' />
                    <h3 className="font-family-bold text-uppercase mt-0 mb-4">Order Please Successful</h3>
                    <p className='mb-1'>Order No. : 1234567890</p>
                    <p className='mb-3'>Time : 20 july 20222 19:17</p>
                    {/* <p className='mb-5'>Thank you! Your payment is complete</p> */}

                    <Link to="/dashboard" className='btn cbtn-primary'>Go to Dashboard</Link>
                </div>
            </div>}


        </>
    );
}

export default withRouter(Card);
