import React, { useState, useEffect, useRef } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { GET_CART,GET_COUNTRIES,GET_STATES,ORDER_PLEASE,UPDATE_ORDER_STATUS} from '../../helpers/url_helper';
import SimpleReactValidator from 'simple-react-validator';

const Services = props => {
    let history = useHistory();

    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ });
    const [, forceUpdate] = useState();


    const [cardDetile, SetcardDetile] = useState({});
    const [countriesList, SetCountries] = useState({});
    const [statesList, SetStates] = useState({});

    useEffect(() => {
        async function get_detaile() {
            await get_cart();
            await get_countries();
        }
        get_detaile();
    }, [])

    const get_cart = async () => {
        var id = 0;
        var qty = 0;
        var cart_detile = sessionStorage.getItem('cart');
        if (cart_detile) {
            cart_detile = JSON.parse(cart_detile);
            id = cart_detile.id;
            qty = cart_detile.qty;
        }else{
            history.push("/pricing");
        }
        form_inputs.plan_id = id;
        form_inputs.qty = qty;
        var res_data = await post(GET_CART, { id: id, qty: qty });
        if (res_data.status) {
            SetcardDetile(res_data.data);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const get_countries = async () => {
        var res_data = await post(GET_COUNTRIES, {});
        if (res_data.status) {
            SetCountries(res_data.data.countries_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }
    
    const get_states = async (country_id) => {
        var res_data = await post(GET_STATES, {country_id : country_id});
        if (res_data.status) {
            SetStates(res_data.data.states_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    const handleInputChange = (event) => {
        event.persist();
        if(event.target.name === 'country'){
            get_states(event.target.value)
            form_inputs.country = event.target.value;
            form_inputs.state = '';
            setInputs(form_inputs);
        }else{
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data =  await post(ORDER_PLEASE,form_inputs,{});
            if(res_data.status){
                UpdateOrderStatus(res_data.data.order_no);
                toast(res_data.message);
                sessionStorage.removeItem('cart');
                history.push("/successful");
            }else{
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const UpdateOrderStatus = async (order_no) => {
        SetButtonDisabled(true);
        var res_data =  await post(UPDATE_ORDER_STATUS,{order_no : order_no},{});
        if(res_data.status){
        }else{
            toast.error(res_data.message);
        }
        SetButtonDisabled(false);
    } 

    return (
        <>
            <section className="section check-out">
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h1 className="font-family-bold text-uppercase mt-0">Payment</h1>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-8 mb-3">
                                <div className='row mt-3'>
                                    <div className='col-sm-12 mb-2'>
                                        <h6 className="font-family-bold text-uppercase mt-0">Account Info</h6>
                                        <hr className='m-0'/>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Registered Email Address</label>
                                            <input type="text" name='email' value={form_inputs.email} onChange={handleInputChange} className="form-control" placeholder='Enter You Registered Email Address' />
                                            {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                            <p className='mt-2 mb-0'><b>Note: </b> Please enter your registered email address. if not any account register system automatic create a account and login details sent.</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 mb-2'>
                                        <h6 className="font-family-bold text-uppercase mt-0">Billing Detile</h6>
                                        <hr className='m-0'/>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Country / region</label>
                                            <select name='country' value={form_inputs.country} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select Country</option>
                                                {countriesList && Object.entries(countriesList).map(([key, value]) => ( 
                                                    <option value={value.value} key={key}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('country', form_inputs.country, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <input type="text" name='name' value={form_inputs.name} onChange={handleInputChange} className="form-control" placeholder='Enter You Full Name' />
                                            {simpleValidator.current.message('name', form_inputs.name, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Company Name</label>
                                            <input type="text" name='company_name' value={form_inputs.company_name} onChange={handleInputChange} className="form-control" placeholder='Enter You Company Name (optional)' />
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input type="text" name='address' value={form_inputs.address} onChange={handleInputChange} className="form-control" placeholder='Enter You Address' />
                                            {simpleValidator.current.message('address', form_inputs.address, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" name='city' value={form_inputs.city} onChange={handleInputChange} className="form-control" placeholder='Enter You City' />
                                            {simpleValidator.current.message('city', form_inputs.city, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>State</label>
                                            <select name='state' value={form_inputs.state} onChange={handleInputChange} className="form-control" >
                                                <option value="">Select State</option>
                                                {statesList && Object.entries(statesList).map(([key, value]) => ( 
                                                    <option value={value.value} key={key}>{value.label}</option>
                                                ))}
                                            </select>
                                            {simpleValidator.current.message('state', form_inputs.state, 'required')}
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>Pin Code</label>
                                            <input type="text" name='pin_code' value={form_inputs.pin_code} onChange={handleInputChange} className="form-control" maxLength={8} placeholder='Enter You Pin Code' />
                                            {simpleValidator.current.message('pin_code', form_inputs.pin_code, 'required|numeric')}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Phone Number</label>
                                            <input name='phone_no' type="text" value={form_inputs.phone_no} onChange={handleInputChange} className="form-control" maxLength={12} placeholder='Enter You Phone Number' />
                                            {simpleValidator.current.message('phone_no', form_inputs.phone_no, 'required|numeric')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className='row'>
                                    <div className='col-sm-6'>Sub Total</div>
                                    <div className='col-sm-6 text-right'>${cardDetile.sub_total}</div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-sm-6'>Tax</div>
                                    <div className='col-sm-6 text-right'>${cardDetile.tax}</div>
                                </div>
                                <hr />
                                <div className='row'>
                                    <div className='col-sm-6'><h6 className="font-family-bold text-uppercase">Order total</h6></div>
                                    <div className='col-sm-6 text-right'><h6 className="font-family-bold text-uppercase">${cardDetile.total}</h6></div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-sm-12'>
                                        <button type="submit" disabled={ButtonDisabled} className='btn cbtn-primary w-100 pt-2 pb-2' > {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Proceed To Pay</button>
                                    </div>
                                </div>
                                {/* <div className='mt-3'>
                                    <Link to="/successful" className='mr-5' >Success</Link>
                                    <Link to="/failed" >Failed</Link>
                                </div> */}
                            </div>
                        </div>
                    </form>

                </div>
            </section>
        </>
    );
}
export default withRouter(Services)
