import React, { useState, useEffect } from 'react';
import { useHistory,withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { GET_USER_PAYMENT,GET_PAYMENT_DETAIL } from '../../helpers/url_helper';
import { getToken,amountFormat } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import Logo from './../../assets/images/logo.png';
const Payment = props => {
    let history = useHistory();
    const [PaymentDetile,SetPaymentDetile] = useState();

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_USER_PAYMENT,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "payment_id" },
                    { "data": "order_no" },
                    { "data": "date" },
                    { "data": "plan_name" },
                    { "data": "full_name" },
                    { "data": "total_qty" },
                    { "data": "grand_total" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.payment_status === 0 && <button className="border-0 badge badge-info pt-1 pb-1" >Pending</button>}
                                {data.payment_status === 1 && <button className="border-0 badge badge-success pt-1 pb-1"  >Success</button>}
                                {data.payment_status === 2 && <button className="border-0 badge badge-danger pt-1 pb-1"  >Failed</button>}
                                {data.payment_status === 3 && <button className="border-0 badge badge-danger pt-1 pb-1"  >Cancel</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={getPaymentDetail.bind(this,data.payment_id)} className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const getPaymentDetail = async (payment_id) => {
        var res_data = await post(GET_PAYMENT_DETAIL, { payment_id: payment_id });
        if (res_data.status) {
            SetPaymentDetile(res_data.data);
            console.log(res_data.data);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }
    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Payment History</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Payment Id</td>
                                            <td>Date</td>
                                            <td>Plan Name</td>
                                            <td>Billing Name</td>
                                            <td>Qty</td>
                                            <td>Amount</td>
                                            <td>Payment Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Plan Subscription Detile</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {PaymentDetile && <div className='billing'>
                                <div className='row align-items-center'>
                                    <div className='col-sm-1'>
                                        <img src={Logo}></img>
                                    </div>
                                    <div className='col-sm-11'>
                                        <h5>TapGrid Pvt Ltd.</h5>
                                        <p>2901 N Ashton Blvd Ste 300 <br />Lehi, UT 84043 United States</p>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-sm-4'>
                                        <div className='row'>
                                            <div className='col-sm-6'><b>Payment Date : </b></div>
                                            <div className='col-sm-6'>{PaymentDetile.date}</div>
                                            <div className='col-sm-6'><b>Subscription Id : </b></div>
                                            <div className='col-sm-6'>{PaymentDetile.subscription_no}</div>
                                            <div className='col-sm-6'><b>Payment Tx Id : </b></div>
                                            <div className='col-sm-6'>{PaymentDetile.order_no}</div>
                                            <div className='col-sm-6'><b>Payment Status : </b></div>
                                            <div className='col-sm-6'>
                                                {PaymentDetile.payment_status === 0 && <span className='text-info'>Pending</span>}
                                                {PaymentDetile.payment_status === 1 && <span className='text-success'>Success</span>}
                                                {PaymentDetile.payment_status === 2 && <span className='text-danger'>Failed</span>}
                                                {PaymentDetile.payment_status === 3 && <span className='text-danger'>Cancel</span>}
                                            </div>
                                            {/* <div className='col-sm-6'><span className='text-danger'>UNPAID</span></div> */}
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='row'>
                                            <div className='col-sm-6'><b>Company Name : </b></div>
                                            <div className='col-sm-6'>{PaymentDetile.company_name}</div>
                                            <div className='col-sm-6'><b>Email : </b></div>
                                            <div className='col-sm-6'>{PaymentDetile.email}</div>
                                            <div className='col-sm-6'><b>Phone No : </b></div>
                                            <div className='col-sm-6'>{PaymentDetile.phone_number}</div>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <b>Billing Detail : </b> <br />
                                                <p className='mb-0'>{PaymentDetile.full_name}</p>
                                                <p className='mb-0'>{PaymentDetile.address}</p>
                                                <p className='mb-0'>{PaymentDetile.city} , {PaymentDetile.state_name} , {PaymentDetile.country_name} - {PaymentDetile.pin_code}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-sm-12'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <td>Sr.</td>
                                                    <td>Code</td>
                                                    <td>Item</td>
                                                    <td>Qty</td>
                                                    <td className='text-left'>Price</td>
                                                    <td className='text-right'>Amount</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>{PaymentDetile.code}</td>
                                                    <td>{PaymentDetile.plan_name}</td>
                                                    <td>{PaymentDetile.total_qty}</td>
                                                    <td className='text-left'>${amountFormat(PaymentDetile.price)}</td>
                                                    <td className='text-right'>${amountFormat(PaymentDetile.sub_total)}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4} rowSpan={3}></td>
                                                    <td>Sub Total : </td>
                                                    <td className='text-right'>${amountFormat(PaymentDetile.sub_total)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tax : </td>
                                                    <td className='text-right'>${amountFormat(PaymentDetile.total_tax)}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Total : </b></td>
                                                    <td className='text-right'><b>${amountFormat(PaymentDetile.grand_total)}</b></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Payment);
