import React, { useState, useRef, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { post } from '../../../helpers/api_helper';
import { GET_PROFILE_DETAILES, POST_SAVE_CONTACT_FORM } from '../../../helpers/url_helper';
import { toast } from 'react-toastify';
import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';

const Theme = props => {
    let history = useHistory();

    const [form_inputs, setInputs] = useState({ 'theme_id': props.match.params.id, show_contact_form_tab: 0 });

    useEffect(() => {
        async function get_detaile() {
            await get_theme_detail();
        }
        get_detaile();
    }, [props.match.params.id])

    const get_theme_detail = async () => {
        var res_data = await post(GET_PROFILE_DETAILES, { 'id': props.match.params.id }, {});
        if (res_data.status) {
            setInputs({ 'theme_id': props.match.params.id, show_contact_form_tab: res_data.data.detail.show_contact_form_tab });
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }

    // input text change handler
    const handleInputChange = async (event) => {
        event.persist();
        if (event.target.name === 'show_contact_form_tab') {
            form_inputs.show_contact_form_tab = (event.target.checked) ? '1' : '0';
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? '1' : '0' }));
            var res_data = await post(POST_SAVE_CONTACT_FORM, form_inputs, {});
            if (!res_data.status) {
                toast.error(res_data.message);
            }
        }
    }

    return (
        <>

            <div className='row mt-3'>
                <div className='col-sm-8'>
                    <div className='card'>
                        <div className="card-header">
                            <h5 className='m-0'>Contact Form</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group mb-3">
                                <label className="form-label mb-3">Collect information and receive messages from visitors.</label><br />
                            </div>
                            <div className='row'>
                                <div className='col-8'>
                                    <label>Show Contact Form</label>
                                </div>
                                <div className='col-4'>
                                    <label className="switch"> 
                                        <input name="show_contact_form_tab" onChange={handleInputChange} checked={(form_inputs.show_contact_form_tab > 0)? true : false} type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Theme);
