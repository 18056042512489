import React, { useState } from 'react';


// component
import Header from '../component/header';
import UserSidebar from '../component/UserSidebar';
import '../assets/css/style.css'

function Default({ children }) {
    window.scrollTo(0, 0);
    const [MenuHide, setMenuHide] = useState('');

    const handleMenu = () => {
        if(MenuHide){
            setMenuHide('');
        }else{
            setMenuHide('hide_menu');
        }
    }
   
    return (
        <>  
            <div className={MenuHide}>
                <Header onChangeHandleMenu={handleMenu} />
                <div className='main_body'>
                    <UserSidebar/>
                    <div className='web_body p-20'>
                        <div className='container-fluid'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Default;
