import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { GET_All_LEADS, DELETE_LEAD, GET_USER_PROFILE_LIST } from '../../helpers/url_helper';
import { getToken } from '../../helpers/Helper';
import { del, post } from '../../helpers/api_helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import { toast } from 'react-toastify';
const Subscription = props => {
    let history = useHistory();

    const [LeadInfo, SetLeadInfo] = useState({});
    const [ProfileList, SetProfileList] = useState([]);
    const [ProfileId, SetProfileId] = useState(0);
    const [Id, SetId] = useState(0);

    useEffect(async () => {
        var res_data = await post(GET_USER_PROFILE_LIST);
        if (res_data.status) {
            SetProfileList(res_data.data.profile_liste);
            var id = (res_data.data.profile_liste.length > 0) ? res_data.data.profile_liste[0].theme_id : 0;
            SetProfileId(id);
            get_list(id);
        }
    }, [])

    // get list
    const get_list = (profile_id = 0) => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_All_LEADS+"?profile_id="+profile_id,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "inquiry_id" },
                    { "data": "created_at" },
                    { "data": "name" },
                    { "data": "email" },
                    { "data": "mobile_number" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={() => { SetLeadInfo(data) }} className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                <button className="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2" onClick={() => { SetId(data.inquiry_id); }} data-toggle="modal" data-target="#deleteModal" ><i className="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_LEAD + "?id=" + Id);
        if (res_data.status) {
            toast(res_data.message);
            get_list(ProfileId);
        } else {
            toast.error(res_data.message);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        SetProfileId(event.target.value);
        get_list(event.target.value);
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Leads</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-4'></div>
                                <div className='col-sm-4'>
                                    <select name="country_id" className="form-control" value={ProfileId} onChange={handleInputChange}>
                                        <option value="0">Select Profile</option>
                                        {ProfileList && Object.entries(ProfileList).map(([key, value]) => (
                                            <option value={value.theme_id}>{value.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Date</td>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Mobile Number</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Lead Detile</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <h6 className='mb-0'>Date</h6>
                                    <p className='mb-1'>{LeadInfo.created_at}</p>
                                </div>
                                <div className='col-sm-6'>
                                    <h6 className='mb-0'>Name</h6>
                                    <p className='mb-1'>{LeadInfo.name}</p>
                                </div>
                                <div className='col-sm-6'>
                                    <h6 className='mb-0'>Email</h6>
                                    <p className='mb-1'>{LeadInfo.email}</p>
                                </div>
                                <div className='col-sm-6'>
                                    <h6 className='mb-0'>Mobile Number</h6>
                                    <p className='mb-1'>{LeadInfo.mobile_number}</p>
                                </div>
                                <div className='col-sm-12'>
                                    <h6 className='mb-0'>Message</h6>
                                    <p className='mb-1'>{LeadInfo.message}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this record?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Subscription);
