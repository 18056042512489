import React, { useState, useEffect } from 'react';
import { useHistory,withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { GET_USER_SUBSCRIPTION } from '../../helpers/url_helper';
import { getToken } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

const Subscription = props => {
    let history = useHistory();

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_USER_SUBSCRIPTION,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }, { "targets": 0, "visible": false }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "subscription_id" },
                    { "data": "subscription_no" },
                    { "data": "plan_name" },
                    { "data": "total_card" },
                    // { "data": "start_date" },
                    { "data": "end_date" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.status === 1 && <button className="border-0 badge badge-success pt-1 pb-1"  >Active</button>}
                                {data.status === 2 && <button className="border-0 badge badge-danger pt-1 pb-1"  >Expired</button>}
                            </>, td)
                        }
                    },
                    // {
                    //     "data": null, 'createdCell': (td, data) => {
                    //         ReactDOM.render(<>
                    //             <button onClick={getPaymentDetail.bind(this, data.payment_id)} className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                    //         </>, td)
                    //     }
                    // },
                ]
            });
        });
    }


    return (
        <>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Subscription Detile</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Subscription Id</td>
                                            <td>Plan Name</td>
                                            <td>Total Card</td>
                                            {/* <td>Start Date</td> */}
                                            <td>Expired Date</td>
                                            <td>Plan Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Subscription);
