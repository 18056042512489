import React, { useState, useEffect } from 'react';
import { useHistory,withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import {get} from '../../helpers/api_helper';
import {GET_PRICING} from '../../helpers/url_helper';
import mobile_with_card from './../../assets/images/mobile_with_card.png';

const Services = props => {
    let history = useHistory();

    const [pricing,SetPricing] = useState();

    useEffect(() => {
        async function get_detaile () {
            var res_data = await get(GET_PRICING);
            if (res_data.status) {
                SetPricing(res_data.data);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
        }
        get_detaile();
    }, [])

    const addToCart = (id) => {
        sessionStorage.setItem('cart',JSON.stringify({'id' : id,'qty' : '1'}));
        history.push("/cart");
    }

    return (
        <>
            <section className="section pricing">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-4">

                        </div>
                        <div className="col-sm-4 text-center">
                            <h1 className="font-family-bold text-uppercase ">Pricing</h1>
                        </div>
                        <div className="col-sm-4 text-right">
                            <img src={mobile_with_card} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="pricein-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <h4 className="font-family-bold">Feature</h4>
                                            </th>
                                            <th className="text-center">
                                                <h6 className=" font-family-regular ">
                                                    <div className="text-uppercase">Individual <br /> (Trapgid Branding)</div>

                                                </h6>
                                            </th>
                                            <th className="text-center">
                                                <h6 className="font-family-regular ">
                                                    <div className="text-uppercase">Individual <br /> (White-Labeled)</div>
                                                </h6>
                                            </th>
                                            <th className="text-center">
                                                <h6 className="font-family-regular">
                                                    <div className="text-uppercase">Enterprise</div>
                                                </h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>NFC</td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>QR Code</td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>No App Needed</td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Social Accounts</td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Save directly on phone</td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Supports all major phone</td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Update in real time</td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>White- Labeled</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Switch to connect mode</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Keyword</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Link Social account to App</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Analytics</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Landing Page</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Multi Theme</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Location tracking</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td>Contact share back</td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-times" aria-hidden="true"></i></td>
                                            <td className="text-center"><i className="fa fa-check" aria-hidden="true"></i></td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>
                                                <h4 className="font-family-bold">Price</h4>
                                            </td>
                                            <td className="text-center">
                                                <h6 className="font-family-bold text-stroke mb-0">${pricing?.trapgid?.mrp}</h6>
                                                <h5 className="font-family-bold">${pricing?.trapgid?.price}</h5>
                                                <button onClick={addToCart.bind(this,pricing?.trapgid?.id)} type="button" className="btn cbtn-primary">Buy Now</button>
                                            </td>
                                            <td className="text-center">
                                                <h6 className="font-family-bold text-stroke mb-0">${pricing?.white_labeled?.mrp}</h6>
                                                <h5 className="font-family-bold">${pricing?.white_labeled?.price}</h5>
                                                <button onClick={addToCart.bind(this,pricing?.white_labeled?.id)} type="button" className="btn cbtn-primary">Buy Now</button>
                                            </td>
                                            <td className="text-center">
                                                <h6>&nbsp;</h6>
                                                <h5>&nbsp;</h5>
                                                <button type="button" data-toggle="modal" data-target="#exampleModal" className="btn cbtn-primary">REQUEST</button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6 text-center">
                            <h3 className="font-family-bold">We Are Flexible.</h3>
                            <p className="pt-2 mb-0 pb-2">
                                We have designed the prices so that ir doesn't pinch your
                                <br /> pocket and fits in your budget. Every business, Big or
                                <br /> Small can benefit from our pricing modals.
                            </p>
                            <p>Three different solutions to choose from! So what are you
                                waiting for?</p>
                            <a href="#" className="btn cbtn-primary mt-3">START NOW</a>
                        </div>
                        <div className="col-sm-3">
                            <img src={mobile_with_card} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section quest-back-color">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-5 text-center">
                            <img src={mobile_with_card} />
                        </div>
                        <div className="col-sm-7">
                            <h1 className="font-family-bold text-white">Have more questions?</h1>
                            <p className="font-family-regular pt-2 mb-3 pb-2">
                                Feel free to get in touch
                                <br />
                                We would happy to clarify any doubt.
                            </p>
                            <a href="#" className="btn cbtn-primary text-uppercase ">
                                Get in touch
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section more-section-background position-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12">
                            <div className="title text-center">
                                <div className="text-font-size font-family-regular text-uppercase text-white line-height">
                                    Grow Your <br /> business Network <br /> With Just a Tap
                                </div>
                                <p className="subcontent-margin mt-4 mb-4">
                                    Networking couldn't be easier than this. Just a single tap and
                                    share all <br /> your contact information including your Social Media
                                    profiles. <br /> TapGrid - Your Go to Online Business Card.
                                </p>
                                <a href="#" className="btn cbtn-white text-uppercase">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="exampleModal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Pricing On Request</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group mb-3">
                                    <label className="label">Full Name</label>
                                    <input type="text" name='text' className="form-control" placeholder="Enter Your Full Name" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="label">Email</label>
                                    <input type="text" name='email' className="form-control" placeholder="Enter Your Email" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="label">Phone No</label>
                                    <input type="text" name='phone_no' className="form-control" placeholder="Enter Your Phone No" />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="label">Select Country</label>
                                    <select name='country' className="form-control">
                                        <option>Select Country</option>
                                        <option>Country 1</option>
                                        <option>Country 2</option>
                                        <option>Country 3</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="label">Message</label>
                                    <textarea name='message' rows={4} className="form-control" placeholder="Enter Your Message" ></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            <button type="button" className="btn cbtn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(Services)
