import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { POST_ADD_PROFILE, GET_USER_PROFILE_LIST,DELETE_USER_PROFILE } from '../../../helpers/url_helper';
import { post,del } from '../../../helpers/api_helper';
import { img_view} from '../../../helpers/Helper';
import Avatar from './../../../assets/images/avatar.jpg';
var md5 = require('md5');
const Profile = props => {
    const [ProfileList, SetProfileList] = useState([]);

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [profile_inputs, setProfileInputs] = useState({ name: '', heading: '', company_name: '' });
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [ThemeId, setThemeId] = useState(0);


    useEffect(() => {
        async function get_detaile() {
            await get_profile();
        }
        get_detaile();
    }, [])

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setProfileInputs(inputs => ({ ...profile_inputs, [event.target.name]: event.target.value }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_PROFILE, profile_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                window.$('#AddModal').modal('hide')
                setProfileInputs({ name: '', heading: '', company_name: '' });
                await get_profile();
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const get_profile = async (event) => {
        var res_data = await post(GET_USER_PROFILE_LIST);
        if (res_data.status) {
            SetProfileList(res_data.data.profile_liste);
        } else {
            toast.error(res_data.message);
        }
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_USER_PROFILE + "?theme_id=" + ThemeId);
        if (res_data.status) {
            toast(res_data.message);
            get_profile();
        } else {
            toast.error(res_data.message);
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-6'>
                    <h5>Your Profile</h5>
                </div>
                <div className='col-sm-6 text-right'>
                    <button type='button' data-toggle="modal" data-target="#AddModal" className="btn c-btn-primary text-white btn-sm ml-3 pt-1 pb-1">+ Add</button>
                </div>
            </div>

            <div className='row mt-4'>
                {ProfileList && Object.entries(ProfileList).map(([key, profile]) => (
                    <div key={key} className='col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4'>
                        <div className='user-card pt-3 pl-3 pr-3 pb-0'>
                            <div className='row'>
                                <div className='col-8'>
                                    <h5 className='mt-0'>{profile.name}</h5>
                                    <p className='mb-0'>{profile.heading} {profile.company_name}</p>
                                    <p className='mb-0'>-</p>
                                    <p className='mb-0'>-</p>
                                </div>
                                <div className='col-4'>
                                    <div className="profile">
                                        {profile.photo && <img alt='profile' src={img_view(profile.photo)} className="rounded-circle" width="80" />}
                                        {!profile.photo && <img alt='profile' src={Avatar} className="rounded-circle" width="80" />}
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4 link-button'>
                                <div className='col-4'>
                                    <Link to={'/profile/edit/'+ profile.theme_id} ><i className="fa fa-pencil" aria-hidden="true"></i> Edit</Link>
                                </div>
                                <div className='col-4'>
                                    <Link to={'/view-profile/'+md5(""+profile.theme_id+"")} target="_blank"><i className="fa fa-eye" aria-hidden="true"></i> View</Link>
                                </div>
                                <div className='col-4'>
                                    <Link to="#" onClick={() => {setThemeId(profile.theme_id)}} data-toggle="modal" data-target="#deleteModal"><i className="fa fa-trash" aria-hidden="true"></i> Delete</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {!ProfileList.length && <div className='col-sm-12'>
                    <p className='text-center'>No any Profile</p>
                </div>}
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="AddModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header pt-2 pb-2">
                            <h5 className="modal-title mt-0" id="exampleModalLabel">Add Profile</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Name *</label>
                                            <input name="name" className="form-control" value={profile_inputs.name} onChange={handleInputChange} type="text" />
                                            {simpleValidator.current.message('name', profile_inputs.name, 'required')}
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Heading</label>
                                            <input name="heading" className="form-control" value={profile_inputs.heading} onChange={handleInputChange} type="text" />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Company Name</label>
                                            <input name="company_name" className="form-control" value={profile_inputs.company_name} onChange={handleInputChange} type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={ButtonDisabled} className="btn btn-secondary c-btn-danger btn-sm" data-dismiss="modal">Close</button>
                                <button type="submit" disabled={ButtonDisabled} className="btn btn-primary c-btn-primary btn-sm"> {ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header pt-2 pb-2">
                            <h5 className="modal-title mt-0" id="exampleModalLabel">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete this Profile?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                            <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Profile);
