import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
// import { get } from '../../helpers/api_helper';
// import { GET_DISTRIBUTOR_DASHBOARD } from '../../helpers/url_helper';
// import { toast } from 'react-toastify';

import Card_04 from "./../../assets/images/Card_04.png"
import Card_10 from "./../../assets/images/Card_10.png"

import Theme_1 from "./../../assets/images/Theme_1.jpg"
import Theme_2 from "./../../assets/images/Theme_2.jpg"
import Theme_3 from "./../../assets/images/Theme_3.jpg"

const Card = props => {
    
    const [step, Setstep] = useState(1);
    // const [totalRetailer, SetTotalRetailer] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            // await get_service();
        }
        get_detaile();
    }, [])

    const next_back = (page) => {
        Setstep(page);
    }
  

    return (
        <>
            <div className='row'>
                <div className='col-sm-12 text-center'>
                    <h4>Progress Bar</h4>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-sm-2'></div>
                <div className='col-sm-8'>
                    <div className="track">
                        <div className={"first step " + (step >= 1 ? 'active' : '') } > <span className="icon"> 1 </span> <span className="text">Choose Card</span> </div>
                        <div className={"step " + (step >= 2 ? 'active' : '')}> <span className="icon"> 2 </span> <span className="text"> Add Details</span> </div>
                        <div className={"step " + (step >= 3 ? 'active' : '')}> <span className="icon"> 3 </span> <span className="text"> Select Profile </span> </div>
                        <div className={"step last " + (step >= 4 ? 'active' : '')}> <span className="icon"> 4 </span> <span className="text">Confirm</span> </div>
                    </div>
                </div>
            </div>

            {/* select card */}
            {step === 1 && <div className='row mt-15'>
                <div className='col-sm-2 pt-2'></div>
                <div className='col-sm-8 text-center mb-4'>
                    <h5>Select Card</h5>
                </div>
                <div className='col-sm-2 text-right'>
                    <button onClick={next_back.bind(this,2)} className='btn c-btn-primary btn-sm text-white' type='button'>{`Next >`}</button>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='card_id' id='radio1' className='nonde' />
                        <label for='radio1' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Card_04} alt='card 1' />
                                <h5 className='mb-0'>Smart Card</h5>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='card_id' id='radio2' className='nonde' />
                        <label for='radio2' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Card_10} alt='card 1' />
                                <h5 className='mb-0'>Smart Card</h5>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='card_id' id='radio3' className='nonde' />
                        <label for='radio3' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Card_04} alt='card 1' />
                                <h5 className='mb-0'>Smart Card</h5>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='card_id' id='radio4' className='nonde' />
                        <label for='radio4' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Card_10} alt='card 1' />
                                <h5 className='mb-0'>Smart Card</h5>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='card_id' id='radio5' className='nonde' />
                        <label for='radio5' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Card_04} alt='card 1' />
                                <h5 className='mb-0'>Smart Card</h5>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='card_id' id='radio6' className='nonde' />
                        <label for='radio6' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Card_10} alt='card 1' />
                                <h5 className='mb-0'>Smart Card</h5>
                            </div>
                        </label>
                    </div>
                </div>
            </div>}

            {/* Card Details */}
            {step === 2 && <div className='row mt-15'>
                <div className='col-sm-2 pt-2'>
                    <button onClick={next_back.bind(this,1)} className='btn c-btn-primary btn-sm text-white' type='button'>{`< Back`}</button>
                </div>
                <div className='col-sm-8 text-center mb-4'>
                    <h5>Please Fill Card Details</h5>
                </div>
                <div className='col-sm-2 text-right'>
                    <button onClick={next_back.bind(this,3)} className='btn c-btn-primary btn-sm text-white' type='button'>{`Next >`}</button>
                </div>
                <div className='col-sm-6'>
                    <div className="form-group mb-3">
                        <label className="form-label">Your Name</label>
                        <input name="your_name" className="form-control" type="text" />
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Designation</label>
                        <input name="your_name" className="form-control" type="text" />
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Email Address</label>
                        <input name="your_name" className="form-control" type="text" />
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Phone NUmber</label>
                        <input name="your_name" className="form-control" type="text" />
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className="form-group mb-3">
                        <label className="form-label">Upload Logo</label>
                        <input name="logo" className="form-control" type="file" />
                    </div>
                </div>
            </div>}

            {/* Select Profile */}
            {step === 3 && <div className='row mt-15'>
                <div className='col-sm-2 pt-2'>
                    <button onClick={next_back.bind(this,2)} className='btn c-btn-primary btn-sm text-white' type='button'>{`< Back`}</button>
                </div>
                <div className='col-sm-8 text-center mb-4'>
                    <h5>Select Profile</h5>
                </div>
                <div className='col-sm-2 text-right'>
                    <button onClick={next_back.bind(this,4)} className='btn c-btn-primary btn-sm text-white' type='button'>{`Next >`}</button>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='theme' id='Theme_1' className='nonde' />
                        <label for='Theme_1' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Theme_1} alt='card 1' />
                                <h5 className='mb-0'>Theme 1</h5>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='theme' id='Theme_2' className='nonde' />
                        <label for='Theme_2' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Theme_2} alt='card 1' />
                                <h5 className='mb-0'>Theme 2</h5>
                            </div>
                        </label>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-3 mb-4'>
                    <div className='select-card'>
                        <input type="radio" name='theme' id='Theme_3' className='nonde' />
                        <label for='Theme_3' className='mb-0'>
                            <div className='col-12 text-center'>
                                <img src={Theme_3} alt='card 1' />
                                <h5 className='mb-0'>Theme 3</h5>
                            </div>
                        </label>
                    </div>
                </div>
            </div>}
            
            {/* Confirm */}
            {step === 4 && <div>
                <div className='row mt-15'>
                    <div className='col-sm-2 pt-2'>
                        <button onClick={next_back.bind(this,3)} className='btn c-btn-primary btn-sm text-white' type='button'>{`< Back`}</button>
                    </div>
                    <div className='col-sm-8 text-center mb-4'>
                        <h5>Confirm</h5>
                    </div>
                    <div className='col-sm-2 text-right'>
                    </div>
                </div>
                <div className='row'>
                    {/* <div className='col-sm-6'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label>Country / region</label>
                                        <select className="form-control" >
                                            <option value="">Select Country</option>
                                            <option value="">Country 1</option>
                                            <option value="">Country 2</option>
                                            <option value="">Country 3</option>
                                            <option value="">Country 4</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input type="text" className="form-control" placeholder='Enter You Full Name' />
                                    </div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type="text" className="form-control" placeholder='Enter You Address' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className="form-group">
                                        <label>City</label>
                                        <input type="text" className="form-control" placeholder='Enter You City' />
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className="form-group">
                                        <label>State</label>
                                        <select className="form-control" >
                                            <option value="">Select State</option>
                                            <option value="">State 1</option>
                                            <option value="">State 2</option>
                                            <option value="">State 3</option>
                                            <option value="">State 4</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className="form-group">
                                        <label>Pin Code</label>
                                        <input type="text" className="form-control" placeholder='Enter You Pin Code' />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="text" className="form-control" placeholder='Enter You Email Address' />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <input type="text" className="form-control" placeholder='Enter You Phone Number' />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    <div className='col-sm-6'>
                        <div className='text-center'>
                            <h6>Card</h6>
                            <img src={Card_10} style={{ "max-width": '200px' }} />
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='text-center'>
                            <h6>Select Theme</h6>
                            <img src={Theme_3} style={{ "max-width": '200px' }} />
                        </div>
                    </div>
                    <div className='col-sm-12 text-center mt-3'>
                        <button className='btn c-btn-primary btn-sm text-white' type='button'>Confirm Shipping</button>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default withRouter(Card);
